import cdiLocationCache from "@/cache/cdiLocation.cache";

export const territoryStore = {
    state: {
        name: 'territory',
        title: 'Territory',
        titlePlural: 'Territories',
        nav: {
            base: 'territory'
        },
        search: {
            endPoint: 'territory',
            formFields: {
                searchIndex: {
                    val: 0,
                    qsField: '[search_index]',
                }
            },
            resultFields: {
                territory_id: { header: 'ID', link: 'territory', textField: 'territory_name'},
                cdi_location_id: { header: 'CDI Location', link: 'cdi_location', textField: 'cdi_location' },
            }
        },
        object: {
            fields: [
                {
                    name: 'territory_id',
                    label: 'Territory ID',
                    component: 'DetailsStatic'
                },
                {
                    name: 'territory_name',
                    label: 'Name',
                    component: 'DetailsText'
                },
                {
                    name: 'cdi_location_id',
                    label: 'CDI Location',
                    component: 'DetailsSelect',
                    options: cdiLocationCache.CDI_LOCATIONS
                }
            ]
        }
    }
}